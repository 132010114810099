import React, { Component } from 'react';
// import { Trans } from 'react-i18next';
//class Footer extends Component {
const Footer = () => {
//  render () {
    return (
      <footer className="footer">
        <div className="container-fluid">
          <div className='row'>
            <div className="col-6 d-sm-flex justify-content-sm-between py-2 w-100">
              <span className="text-muted text-center text-sm-left d-block align-content-end">Copyright © <a href="https://www.rescobranzas.com/" target="_blank" rel="noopener noreferrer">rescobranzas.com </a>2025</span>
            </div>
            <div className='col-6 d-flex justify-content-end'>
            <img
                src={require('../../assets/images/logo.png')} width="60" height="60" alt="logo"
              />
            </div>
          </div>
        </div>
      </footer>
    );
//  }
}

export default Footer;