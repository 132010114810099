import React, { Component, useEffect, useState } from 'react';
import { Link, /*withRouter*/ } from 'react-router-dom';
import { Collapse, Dropdown } from 'react-bootstrap';
import axios from 'axios';

import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

const URI1 = process.env.REACT_APP_API_URL+'afiliada'
const URIsistema = process.env.REACT_APP_API_URL

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}


//class Sidebar extends Component {
const Sidebar = (props) => {

  const rol=props.rol
  const permisos = props.permisos

  const canView = (name, type, permisos) => {
    if(permisos){
    // Si la lista de permisos está vacía, significa acceso completo
    if (permisos?.length === 0 || rol==1) return true;
  
    var result=permisos.some(
      (permiso) => permiso.nombre === name && permiso.tipo === type
    );
    // Busca permisos específicos para el nombre y tipo
    return result
  }else{
    return false
  }
  };

  const sidebarConfig = {
    empresa: {
      icon: "mdi mdi-briefcase",
      title: "Empresa",
      path: "/empresa",
      subItems: [
        { title: "Datos", path: "/empresa/datosEmpresa", name: "datos" },
        { title: "Agencias", path: "/empresa/verAgencias", name: "agencias" },
        { title: "Nómina", path: "/empresa/nominaEmpresa", name: "nomina" },
        { title: "Departamentos y Cargos", path: "/empresa/departamentosCargos", name: "departamentosCargos" },
        { title: "Usuarios", path: "/empresa/usuarios", name: "usuarios" },
      ],
      name: "empresa", // Nombre único para el elemento
    },
    productos: {
      icon: "mdi mdi-package-variant",
      title: "Productos",
      path: "/productos",
      subItems: [
        { title: "Productos Débito", path: "/productos/mostrarProductos", name: "productosDebito" },
        { title: "Productos Facturación", path: "/productosFacturacion/mostrarProductos", name: "productosFacturacion" },
        { title: "Categorías Productos Facturación", path: "productosFacturacion/categorias", name: "categoriasProductosFacturacion" },
      ],
      name: "productos", // Nombre único para el elemento
    },
    clientes: {
      icon: "mdi mdi-account-multiple",
      title: "Clientes",
      path: "/clientes",
      subItems: [
        { title: "Clientes", path: "/clientes/clientes", name: "clientes" },
        { title: "Certificados", path: "/clientes/certificados", name: "certificados" },
      ],
      name: "clientes", // Nombre único para el elemento
    },
    afiliaciones: {
      icon: "mdi mdi-playlist-plus",
      title: "Afiliaciones",
      path: "/afiliaciones",
      subItems: [
        { title: "Carga masiva de certificados", path: "/afiliaciones/afiliacionMasiva", name: "cargaMasivaCertificados" },
        { title: "Ingreso Individual", path: "/afiliaciones/afiliacionIndividual", name: "ingresoIndividual" },
        { title: "Consultar Afiliaciones", path: "/afiliaciones/consultarAfiliaciones", name: "consultarAfiliaciones" },
      ],
      name: "afiliaciones", // Nombre único para el elemento
    },
    cobranzas: {
      icon: "mdi mdi-cash-usd",
      title: "Cobranzas",
      path: "/cobranzas",
      subItems: [
        { title: "Precuotas/Cuotas Débito", path: "/cobranzas/precuotas-cuotas-debito", name: "precuotasCuotas" },
        { title: "Última Nómina", path: "/cobranzas/ultimaNomina", name: "ultimaNomina" },
        { title: "Gestión Débitos Bancarios", path: "/cobranzas/gestionDebitos", name: "gestionDebitos" },
        { title: "Liquidaciones Empresas", path: "/cobranzas/liquidacionesProductos", name: "liquidacionesEmpresas" },
      ],       
      name: "cobranzas", // Nombre único para el elemento
    },
    facturacion: {
      icon: "mdi mdi-receipt",
      title: "Facturacion",
      path: "/facturacion",
      subItems: [
        { title: "Firma Electrónica", path: "/facturacion/firmaElectronicaEmpresa", name: "firmaElectronica" },
        { title: "Ventas", path: "/facturacion/ventas", name: "ventas" },
      ],
      name: "facturacion", // Nombre único para el elemento
    },
    contabilidad: {
      icon: "mdi mdi-calculator",
      title: "Contabilidad",
      path: "/contabilidad",
      subItems: [
        { title: "Estados de Cuenta", path: "/contabilidad/estadosCuenta", name: "estadosCuenta" },
      ],                
      name: "contabilidad", // Nombre único para el elemento
    },
    mails: {
      icon: "mdi mdi-email",
      title: "Mails",
      path: "/correos",
      subItems: [
        { title: "Remitentes", path: "/correos/remitentes", name: "remitentes" },
        { title: "Plantillas", path: "/correos/plantillas", name: "plantillas" },
        { title: "Campañas", path: "/correos/campaigns", name: "campanias" },
      ],
      name: "correos", // Nombre único para el elemento
    },
    reportes: {
      icon: "mdi mdi-file-document",
      title: "Reportes",
      path: "/reportes",
      subItems: [
        { title: "Reportes de Cartera", path: "/clientes/resAfiliadaClientes", name: "reportes" },
      ],       
      name: "reportes", // Nombre único para el elemento
    },
    ifis: {
      icon: "mdi mdi-wallet-membership",
      title: "RES cobranzas IFIS",
      path: "/ifis",
      subItems: [
        { title: "Emisores Bancarios IFIS", path: "/clientes/resAfiliadaClientes", name: "ifis" },
      ],                
      name: "ifis", // Nombre único para el elemento
    },
    administracion: {
      icon: "mdi mdi-account-star",
      title: "Administración general",
      path: "/administracion",
      subItems: [
        { title: "Empresas Afiliadas", path: "/administracion/afiliadas", name: "afiliadas" },
        { title: "Usuarios", path: "/administracion/usuarios", name: "usuarios" },
        { title: "Emisores", path: "/administracion/emisores", name: "emisores" },
        { title: "Servidores de Correo", path: "/administracion/servidoresCorreo", name: "servidoresCorreo" },
        { title: "Roles y Permisos", path: "/administracion/rolesPermisos", name: "rolesPermisos" },
      ],
      name: "administracion", // Nombre único para el elemento
    },
  };
  
  //para visualizar a la imagen logo de la empresa afiliada
  const [imagen, setImagen] = useState('')
  const obtenerImagen = async() => {
    try {
      const img = await axios.post(URI1, 
        {'opcion': 1,
          'idAfiliada': window.localStorage.idEmpresa?window.localStorage.idEmpresa:'-1'
        } 
    )
      setImagen(img.data[0][0].rutaImagen);
    
    } catch (error) {
      console.log(error);
    }
  }

  function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  useEffect(()=>{ 
    var cookieToken = getCookie('token')
    if(cookieToken){
    obtenerImagen();
  }
  },[])

  const [state, setState] = useState({});

  //toggleMenuState(menuState) {
    const toggleMenuState = (menuState) => {
    if (state[menuState]) {
      setState({[menuState] : false});
    } else if(Object.keys(state).length === 0) {
      setState({[menuState] : true});
    } else {
      Object.keys(state).forEach(i => {
        setState({[i]: false});
      });
      setState({[menuState] : true}); 
    }
  }

 /*  componentDidUpdate(prevProps) {
    if (props.location !== prevProps.location) {
      onRouteChanged();
    }
  } */

  useEffect(()=>{ 
      onRouteChanged();
  },[window.location.pathname])

  //onRouteChanged() {
    const onRouteChanged = () => {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(state).forEach(i => {
      setState({[i]: false});
    });

    const dropdownPaths = [
      {path:'/apps', state: 'appsMenuOpen'},
      {path:'/basic-ui', state: 'basicUiMenuOpen'},
      {path:'/form-elements', state: 'formElementsMenuOpen'},
      {path:'/tables', state: 'tablesMenuOpen'},
      {path:'/icons', state: 'iconsMenuOpen'},
      {path:'/charts', state: 'chartsMenuOpen'},
      {path:'/user-pages', state: 'userPagesMenuOpen'},
      {path:'/error-pages', state: 'errorPagesMenuOpen'},
    ];

    dropdownPaths.forEach((obj => {
      if (isPathActive(obj.path)) {
        setState({[obj.state] : true})
      }
    }));
 
  }

  //isPathActive(path) {
    const isPathActive = (path) => {
      //console.log(window.location.pathname.startsWith(path))
      //return props.location.pathname.startsWith(path);
      return window.location.pathname.startsWith(path);
    }
  
  //componentDidMount() {
    useEffect(()=>{
      onRouteChanged();
      // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
      const body = document.querySelector('body');
      document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
        
        el.addEventListener('mouseover', function() {
          if(body.classList.contains('sidebar-icon-only')) {
            el.classList.add('hover-open');
          }
        });
        el.addEventListener('mouseout', function() {
          if(body.classList.contains('sidebar-icon-only')) {
            el.classList.remove('hover-open');
          }
        });
      });
    },[])
    
  //}

  //render () {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
          {/* <a className="brand-logo" href="index.html"><img src={require('../../assets/images/logo.svg')} width="50" height="50"alt="logo" /></a> */}
          <Link to="/dashboard"><div className="brand-logo-mini"><img src={require('../../assets/images/logo.png')} width="60" height="60" alt="logo" /></div></Link>
        </div>
        <ul className="nav">
          {//Inicio de secciones del sistema
          }
          <li className="nav-item nav-category">
            <span className="nav-link">Empresa</span>
            <p className="ml-2 navbar-nav" >{window.localStorage.nombreEmpresa}</p>
            <div className='col-sm-12 mt-2 d-flex'>
              {imagen?(<img
                src={`${URIsistema}`+`uploads/imagenesAfiliadas/${imagen}`}
                alt="Logo de la empresa"
                style={{ maxWidth: '80px', maxHeight: '80px' }}
              />)
              :
              (<p>Sin logo disponible</p>)
              }
              </div>
          </li>
          <li className="nav-item nav-category">
            <span className="nav-link">Navegación</span>
          </li>
          <li className={ isPathActive('/dashboard') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
            <Link className="nav-link" to="/dashboard">
              <span className="menu-icon"><i className="mdi mdi-speedometer"></i></span>
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>

          {Object.entries(sidebarConfig).map(([key, config]) => {
        // Verificar si el usuario puede ver el elemento
        if (!canView(config.name, 1, permisos)) {return null;}
        else{

        return (
          <li
            key={key}
            className={
              isPathActive(config.path)
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <div
              className={
                state[key] ? "nav-link menu-expanded" : "nav-link"
              }
              onClick={() => toggleMenuState(key)}
              data-toggle="collapse"
            >
              <span className="menu-icon">
                <i className={config.icon}></i>
              </span>
              <span className="menu-title">{config.title}</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={state[key]}>
              <div>
                <ul className="nav flex-column sub-menu">
                  {config.subItems.map((subItem) => {
                    // Verificar si el usuario puede ver el subelemento
                    if (
                      !canView(subItem.name, 2, permisos)
                    ){
                    return null;
                    }else{
                    return (
                      <li key={subItem.path} className="nav-item">
                        <Link
                          className={
                            isPathActive(subItem.path)
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to={subItem.path}
                        >
                          {subItem.title}
                        </Link>
                      </li>
                    );
                  }
                })
                }
                </ul>
              </div>
            </Collapse>
          </li>
        );
      }
      }
      )}
        </ul>
      </nav>
    );
  //}
}

export default withRouter(Sidebar);
//export default Sidebar;